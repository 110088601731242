import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import tables from "../../../../Data/Tables/Home/Sliders/tables";
import headers from "../../../../Data/Tables/Home/Sliders/headers";
import { get_all_home_sliders } from "../../../../Controllers/Home/Sliders";
import Header from "../../../../Components/Header";
import Loadercomponent from "../../../../Components/Loader";
import Table from "../../../../Components/Table";
import Status from "../../../../Components/Status";
import ActionButton from "../../../../Components/Buttons/action";
import TableImage from "../../../../Components/Tableimage";
import URL from "../../../../Main/url";

const HomeSliderTable = () => {
  const [loader, setLoader] = useState(false);
  const [body, setBody] = useState();

  const formdata = (data) => {
    fetchData(data);
  };

  const actiondata = (data) => {
    fetchData(data);
  };

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const params = {
        search: tabledata?.search,
        sort: tabledata?.sort,
        status: tabledata?.status,
      };

      const sliders = await get_all_home_sliders(params);

      if (sliders?.status) {
        let data = [];

        sliders?.data?.map((value, index) => {
          data?.push({
            Name: (
              <TableImage
                image={URL + "/uploads" + value?.image}
                name={value?.name}
              />
            ),
            Title: value?.title,
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton
                edit={`/app/home/sliders/edit/${value?._id}`}
                id={value?._id}
                api="delete-home-slider"
                actiondata={actiondata}
              />
            ),
          });
        });

        setBody(data);
        setLoader(false);
      } else {
        toast?.error(sliders?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <Table head={tables} body={body} />
      </Loadercomponent>
    </Header>
  );
};

export default HomeSliderTable;
