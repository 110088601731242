import React, { useState } from "react";
import AddButton from "../Buttons/add";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, Button, DatePicker, Input, Select } from "antd";
import {
  DownloadOutlined,
  FilterOutlined,
  SearchOutlined,
  SwapOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Container from "../Container";

const Header = (props) => {
  let navigate = useNavigate();

  const { RangePicker } = DatePicker;

  const formdata = props?.formdata;
  const [headers, setHeaders] = useState(props?.header);

  const handleFormData = (e) => {
    let data = {};
    headers?.components?.map((value, index) => {
      data[value.name] = value.value;
    });

    formdata(data);
  };

  const handleSearch = (e, i) => {
    headers.components[i].value = e?.target?.value;
    setHeaders(headers);
    handleFormData();
  };

  const handleSelect = (e, i) => {
    headers.components[i].value = e;
    setHeaders(headers);
    handleFormData();
  };

  const handleRange = (e, i) => {
    headers.components[i].value.start = e?.[0]?.$d;
    headers.components[i].value.end = e?.[1]?.$d;
    setHeaders(headers);
    handleFormData();
  };

  const handleTabs = (e) => {
    const data = [];
    const lastIndex = e?.length - 1;

    e?.map((value, index) => {
      data.push({
        title: value?.name,
        onClick: () => navigate(value?.path),
        className:
          value?.path && index !== lastIndex
            ? "text-black-200 cursor-pointer hover:text-[#2d53da]"
            : value?.path && index === lastIndex
            ? "breadcrumb-tab-color cursor-pointer hover:text-[#2d53da]"
            : "text-black ",
      });
    });

    return data;
  };

  const handleMenu = (e) => {
    const data = [];

    e?.map((value, index) => {
      data?.push({
        title: value?.name,
        onClick: () => navigate(value?.path),
        className: value?.path ? "cursor-pointer hover:text-[#2d53da]" : "",
      });
    });

    return data;
  };

  return (
    <div>
      <div className="fixed top-16 left-64 right-0 z-20">
        <div className="p-4 flex justify-between items-center border-b bg-white h-[60px]">
          <div>{headers?.name}</div>
          <div>
            <div className="flex">
              {headers?.components?.map((value, index) => {
                return (
                  <div
                    className={
                      headers?.components?.length == index + 1 ? "" : "pr-2"
                    }
                  >
                    {value?.type == "search" ? (
                      <Input
                        allowClear
                        className="search"
                        placeholder=" Search"
                        prefix={
                          <span className="text-gray-500 text-medium">
                            <SearchOutlined />
                          </span>
                        }
                        onChange={(e) => handleSearch(e, index)}
                      />
                    ) : value?.type == "select" || value?.type == "filter" ? (
                      <Select
                        allowClear
                        showSearch={value?.showSearch}
                        filterOption={value?.filterSearch}
                        className="w-32"
                        placeholder={
                          <span>
                            {value?.type == "select" ? (
                              <SwapOutlined
                                style={{ transform: "rotate(90deg)" }}
                              />
                            ) : value?.type == "filter" ? (
                              <FilterOutlined />
                            ) : (
                              ""
                            )}
                            <span className="pl-2">{value?.placeholder}</span>
                          </span>
                        }
                        options={value?.options}
                        onChange={(e) => handleSelect(e, index)}
                      />
                    ) : value?.type == "upload" ? (
                      <Button className="text-[#BFBFBF]">
                        <div className="flex">
                          <div className="">Export</div>
                          <div className="pl-4 pt-0.5">
                            <UploadOutlined />
                          </div>
                        </div>
                      </Button>
                    ) : value?.type == "range" ? (
                      <RangePicker onChange={(e) => handleRange(e, index)} />
                    ) : value?.type == "download" ? (
                      <Button className="text-[#BFBFBF]">
                        <div className="flex">
                          <div className="">Import</div>
                          <div className="pl-4 pt-0.5">
                            <DownloadOutlined />
                          </div>
                        </div>
                      </Button>
                    ) : value?.type == "add" ? (
                      <AddButton onClick={() => navigate(value?.path)} />
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="p-4 bg-white border-b h-[40px] flex justify-between items-center text-xs text-medium text-gray-400 uppercase">
          <div>
            <Breadcrumb
              className="text-regular"
              separator=">"
              items={handleMenu(headers?.navigate?.path)}
            />
            <Breadcrumb
              className="text-regular"
              separator=">"
              items={handleTabs(headers?.navigate?.tabs)}
              lastItemColor="yellow"
            />
          </div>
          <div className="flex">
            {headers?.navigate?.total?.map((value, index) => {
              return (
                <div
                  className={
                    index == headers?.navigate?.total?.length - 1
                      ? "cursor-pointer "
                      : "pr-3 cursor-pointer"
                  }
                >
                  {value?.label} ({value?.value})
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Container>{props?.children}</Container>
    </div>
  );
};

export default Header;
