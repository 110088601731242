import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_project_slider = async (slider) => {
  try {
    let data = new FormData();
    data.append("image", slider?.image[0]);
    data.append("name", slider?.name);
    data.append("status", slider?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-project-slider`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const update_project_slider = async (slider) => {
  try {
    let data = new FormData();
    data.append("id", slider?.id);
    data.append("image", slider?.image[0]);
    data.append("name", slider?.name);
    data.append("status", slider?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-project-slider`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_project_slider = async (slider) => {
  try {
    const data = {
      id: slider?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-project-slider`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_all_project_sliders = async (slider) => {
  try {
    const data = {
      search: slider?.search,
      sort: slider?.sort,
      status: slider?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-project-sliders`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

export {
  create_project_slider,
  update_project_slider,
  get_project_slider,
  get_all_project_sliders,
};
