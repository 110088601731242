const forms = [
  {
    name: "Contacts",
    options: [
      {
        name: "Area",
        type: "input",
        form: {
          type: "text",
          name: "area",
          placeholder: "Area",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "City",
        type: "input",
        form: {
          type: "text",
          name: "city",
          placeholder: "City",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "State",
        type: "input",
        form: {
          type: "text",
          name: "state",
          placeholder: "State",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Country",
        type: "input",
        form: {
          type: "text",
          name: "country",
          placeholder: "Country",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Email",
        type: "input",
        form: {
          type: "text",
          name: "email",
          placeholder: "Email",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Phone",
        type: "input",
        form: {
          type: "text",
          name: "phone",
          placeholder: "Phone",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Location",
        type: "input",
        form: {
          type: "text",
          name: "location",
          placeholder: "Location",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Teams",
        type: "select",
        form: {
          mode: "multiple",
          name: "teams",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Teams",
          api: "get-all-teams",
          options: null,
          value: null,
          required: true,
        },
      },
      {
        name: "Brokers",
        type: "select",
        form: {
          mode: "multiple",
          name: "brokers",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "brokers",
          api: "get-all-brokers",
          options: null,
          value: null,
          required: true,
        },
      },
      // {
      //   name: "Status",
      //   type: "select",
      //   form: {
      //     name: "status",
      //     allowClear: false,
      //     placeholder: "Status",
      //     value: 1,
      //     options: [
      //       {
      //         value: 1,
      //         label: "Active",
      //       },
      //       {
      //         value: 0,
      //         label: "In-active",
      //       },
      //     ],
      //     required: false,
      //   },
      // },
    ],
  },
];

export default forms;
