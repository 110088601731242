import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import forms from "../../../../Data/Forms/Projects/Plans/forms";
import headers from "../../../../Data/Forms/Projects/Plans/headers";
import {
  create_project_plan,
  get_project_plan,
  update_project_plan,
} from "../../../../Controllers/Projects/Plans";
import Header from "../../../../Components/Header";
import Form from "../../../../Components/Form";
import Loadercomponent from "../../../../Components/Loader";
import { convert_to_file } from "../../../../Controllers/Global";

const ProjectPlansForm = () => {
  let { id } = useParams();

  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const plan = id
        ? await update_project_plan({ id: id, ...data?.data })
        : await create_project_plan(data?.data);

      if (plan?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(plan?.message);
        setButtonLoader(false);
      } else {
        toast?.error(plan?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = async (plan) => {
    let image = plan?.image ? plan?.image : "";
    let file = await convert_to_file(image);
    let name = plan?.name ? plan?.name : "";
    let description = plan?.description ? plan?.description : "";
    let type = plan?.type?._id ? plan?.type?._id : "";
    let total = plan?.total ? plan?.total : 0;
    let available = plan?.available ? plan?.available : 0;
    let price_start = plan?.price_start ? plan?.price_start : 0;
    let price_end = plan?.price_end ? plan?.price_end : 0;
    let floors = plan?.floors ? JSON?.parse(plan?.floors) : null;
    let status = plan?.status ? plan?.status : 0;

    let type_option = plan?.type?._id
      ? [{ value: plan?.type?._id, label: plan?.type?.name }]
      : null;

    forms[0].options[0].form.value = [file];
    forms[0].options[1].form.value = name;
    forms[0].options[2].form.value = description;
    forms[0].options[3].form.value = type;
    forms[0].options[3].form.options = type_option;
    forms[0].options[4].form.value = total;
    forms[0].options[5].form.value = available;
    forms[0].options[6].form.value = price_start;
    forms[0].options[7].form.value = price_end;
    forms[0].options[8].form.value = floors;
    forms[0].options[9].form.value = status;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      headers.navigate.path[1].name = "Edit";

      const data = {
        id: id,
      };

      const plan = await get_project_plan(data);

      if (plan?.status) {
        handleData(plan?.data);
      } else {
        toast?.error(plan?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [buttonLoader]);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Form
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default ProjectPlansForm;
