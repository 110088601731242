import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const get_all_enquiries = async (enquiry) => {
  try {
    const data = {
      search: enquiry?.search,
      enquiry: enquiry?.enquiry,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-enquiries `,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

export { get_all_enquiries };
