import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Components/Form";
import toast from "react-hot-toast";
import Header from "../../../../Components/Header";
import {
  create_broker,
  get_broker,
  update_broker,
} from "../../../../Controllers/Contacts/Brokers";
import forms from "../../../../Data/Forms/Contacts/Brokers/forms";
import headers from "../../../../Data/Forms/Contacts/Brokers/headers";
import Loadercomponent from "../../../../Components/Loader";
import AddButton from "../../../../Components/Buttons/add";
import RemoveButton from "../../../../Components/Buttons/delete";
import { convert_to_file } from "../../../../Controllers/Global";

const ContactBrokersForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const broker = id
        ? await update_broker({ ...data?.data, id: id })
        : await create_broker(data?.data);

      if (broker?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(broker?.message);
        setButtonLoader(false);
      } else {
        toast?.error(broker?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = async (broker) => {
    let image = broker?.image ? broker?.image : "";
    let file = await convert_to_file(image);
    let name = broker?.name ? broker?.name : "";
    let designation = broker?.designation ? broker?.designation : "";
    let phone = broker?.phone ? broker?.phone : "";
    let email = broker?.email ? broker?.email : "";
    let description = broker?.description ? broker?.description : "";
    let status = broker?.status ? broker?.status : 0;

    values[0].options[0].form.value = [file];
    values[0].options[1].form.value = name;
    values[0].options[2].form.value = designation;
    values[0].options[3].form.value = phone;
    values[0].options[4].form.value = email;
    values[0].options[5].form.value = description;
    values[0].options[6].form.value = status;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const broker = await get_broker(data);
      if (broker?.status) {
        handleData(broker?.data);
      } else {
        toast?.error(broker?.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default ContactBrokersForm;
