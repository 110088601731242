import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";
import { json } from "react-router-dom";

const create_home = async (home) => {
  try {
    let data = new FormData();
    data.append("sliders", JSON?.stringify(home?.sliders));
    data.append("about_title", home?.about_title);
    data.append("about_description", home?.about_description);
    data.append("project_title", home?.project_title);
    data.append("projects", JSON?.stringify(home?.projects));
    data.append("image", home?.image[0]);
    data.append("features", JSON?.stringify(home?.features));
    data.append("development_title", home?.development_title);
    data.append("developments", JSON?.stringify(home?.developments));
    data.append("services_title", home?.services_title);
    data.append("services", JSON?.stringify(home?.services));
    data.append("testimonials_title", home?.testimonials_title);
    data.append("testimonials", JSON?.stringify(home?.testimonials));
    data.append("blogs_title", home?.blogs_title);
    data.append("blogs", JSON?.stringify(home?.blogs));

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/create-home`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const update_home = async (home) => {
  try {
    let data = new FormData();
    data.append("id", home?.id);
    data.append("sliders", JSON?.stringify(home?.sliders));
    data.append("about_title", home?.about_title);
    data.append("about_description", home?.about_description);
    data.append("project_title", home?.project_title);
    data.append("projects", JSON?.stringify(home?.projects));
    data.append("image", home?.image[0]);
    data.append("features", JSON?.stringify(home?.features));
    data.append("development_title", home?.development_title);
    data.append("developments", JSON?.stringify(home?.developments));
    data.append("services_title", home?.services_title);
    data.append("services", JSON?.stringify(home?.services));
    data.append("testimonials_title", home?.testimonials_title);
    data.append("testimonials", JSON?.stringify(home?.testimonials));
    data.append("blogs_title", home?.blogs_title);
    data.append("blogs", JSON?.stringify(home?.blogs));

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/update-home`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_home = async (home) => {
  try {
    const data = {
      id: home?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-home`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_all_homes = async (home) => {
  try {
    const data = {
      search: home?.search,
      sort: home?.sort,
      unit: home?.unit,
      category: home?.category,
      brand: home?.brand,
      type: home?.type,
      status: home?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-all-homes `, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

export { create_home, update_home, get_home, get_all_homes };
