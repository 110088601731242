import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import forms from "../../../../Data/Forms/Projects/Amenities/forms";
import headers from "../../../../Data/Forms/Projects/Amenities/headers";
import {
  create_project_amenity,
  get_project_amenity,
  update_project_amenity,
} from "../../../../Controllers/Projects/Amenities";
import Header from "../../../../Components/Header";
import Form from "../../../../Components/Form";
import Loadercomponent from "../../../../Components/Loader";
import { convert_to_file } from "../../../../Controllers/Global";

const ProjectAmenitiesForm = () => {
  let { id } = useParams();

  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const amenity = id
        ? await update_project_amenity({ id: id, ...data?.data })
        : await create_project_amenity(data?.data);

      if (amenity?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(amenity?.message);
        setButtonLoader(false);
      } else {
        toast?.error(amenity?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = async (amenity) => {
    let image = amenity?.image ? amenity?.image : "";
    let file = await convert_to_file(image);
    let name = amenity?.name ? amenity?.name : "";
    let title = amenity?.title ? amenity?.title : "";
    let description = amenity?.description ? amenity?.description : "";

    let status = amenity?.status ? amenity?.status : 0;

    forms[0].options[0].form.value = [file];
    forms[0].options[1].form.value = name;
    forms[0].options[2].form.value = title;
    forms[0].options[3].form.value = description;
    forms[0].options[4].form.value = status;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      headers.navigate.path[1].name = "Edit";

      const data = {
        id: id,
      };

      const amenity = await get_project_amenity(data);

      if (amenity?.status) {
        handleData(amenity?.data);
      } else {
        toast?.error(amenity?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [buttonLoader]);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Form
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default ProjectAmenitiesForm;
