const tables = [
  {
    name: "Title",
  },
  {
    name: "Action",
  },
];

export default tables;
