import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Components/Form";
import toast from "react-hot-toast";
import Header from "../../../../Components/Header";

import {
  create_project,
  get_project,
  update_project,
} from "../../../../Controllers/Projects/Projects";
import forms from "../../../../Data/Forms/Projects/Projects/forms";
import headers from "../../../../Data/Forms/Projects/Projects/headers";
import Loadercomponent from "../../../../Components/Loader";
import AddButton from "../../../../Components/Buttons/add";
import RemoveButton from "../../../../Components/Buttons/delete";
import { convert_to_file } from "../../../../Controllers/Global";

const ProjectsForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const project = id
        ? await update_project({ ...data?.data, id: id })
        : await create_project(data?.data);

      if (project?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(project?.message);
        setButtonLoader(false);
      } else {
        toast?.error(project?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = async (project) => {
    let image = project?.image ? project?.image : "";
    let file = await convert_to_file(image);
    let name = project?.name ? project?.name : "";
    let description = project?.description ? project?.description : "";
    let property_size = project?.property_size ? project?.property_size : "";
    let property_price = project?.property_price ? project?.property_price : "";
    let property_type = project?.property_type ? project?.property_type : "";
    let project_duration = project?.project_duration
      ? project?.project_duration
      : "";
    let project_status = project?.project_status ? project?.project_status : "";
    let floors = project?.floors ? project?.floors : "";
    let plan_size = project?.plan_size ? project?.plan_size : "";
    let progress = project?.progress ? project?.progress : "";
    let area = project?.area ? project?.area : "";
    let city = project?.city ? project?.city : "";
    let state = project?.state ? project?.state : "";
    let country = project?.country ? project?.country : "";
    let google_maps = project?.google_maps ? project?.google_maps : "";
    let latitude_longitude = project?.latitude_longitude
      ? project?.latitude_longitude
      : "";
    let sliders = project?.sliders ? JSON?.parse(project?.sliders) : "";
    let gallaries = project?.gallaries ? JSON?.parse(project?.gallaries) : "";
    let amenities = project?.amenities ? JSON?.parse(project?.amenities) : "";
    let nearbies = project?.nearbies ? JSON?.parse(project?.nearbies) : "";
    let types = project?.types ? JSON?.parse(project?.types) : "";
    let plans = project?.plans ? JSON?.parse(project?.plans) : "";

    let status = project?.status ? project?.status : 0;

    let slider_options =
      project?.slider_options?.length > 0 ? project?.slider_options : null;
    let gallery_options =
      project?.gallery_options?.length > 0 ? project?.gallery_options : null;
    let amenity_options =
      project?.amenity_options?.length > 0 ? project?.amenity_options : null;
    let nearby_options =
      project?.nearby_options?.length > 0 ? project?.nearby_options : null;
    let type_options =
      project?.type_options?.length > 0 ? project?.type_options : null;
    let plan_options =
      project?.plan_options?.length > 0 ? project?.plan_options : null;

    forms[0].options[0].form.value = [file];
    forms[0].options[1].form.value = name;
    forms[0].options[2].form.value = description;
    forms[0].options[3].form.value = property_size;
    forms[0].options[4].form.value = property_price;
    forms[0].options[5].form.value = property_type;
    forms[0].options[6].form.value = project_duration;
    forms[0].options[7].form.value = project_status;
    forms[0].options[8].form.value = floors;
    forms[0].options[9].form.value = plan_size;
    forms[0].options[10].form.value = progress;
    forms[0].options[11].form.value = area;
    forms[0].options[12].form.value = city;
    forms[0].options[13].form.value = state;
    forms[0].options[14].form.value = country;
    forms[0].options[15].form.value = google_maps;
    forms[0].options[16].form.value = latitude_longitude;

    forms[0].options[17].form.value = sliders;
    forms[0].options[17].form.options = slider_options;

    forms[0].options[18].form.value = gallaries;
    forms[0].options[18].form.options = gallery_options;

    forms[0].options[19].form.value = amenities;
    forms[0].options[19].form.options = amenity_options;

    forms[0].options[20].form.value = nearbies;
    forms[0].options[20].form.options = nearby_options;

    forms[0].options[21].form.value = types;
    forms[0].options[21].form.options = type_options;

    forms[0].options[22].form.value = plans;
    forms[0].options[22].form.options = plan_options;

    forms[0].options[23].form.value = status;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const project = await get_project(data);
      if (project?.status) {
        await handleData(project?.data);
      } else {
        toast?.error(project?.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default ProjectsForm;
