const headers = {
  name: "Enquiry",
  components: [
    {
      type: "search",
      name: "search",
      placeholder: "Search",
      value: "",
    },
    {
      type: "range",
      name: "enquiry",
      value: {
        start: "",
        end: "",
      },
    },
  ],
  navigate: {
    total: [
      { value: 0, label: "Total" },
      { value: 0, label: "Active" },
      { value: 0, label: "Inactive" },
    ],
  },
};

export default headers;
