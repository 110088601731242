import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import forms from "../../../../Data/Forms/Projects/Sliders/forms";
import headers from "../../../../Data/Forms/Projects/Sliders/headers";
import {
  create_project_slider,
  get_project_slider,
  update_project_slider,
} from "../../../../Controllers/Projects/Sliders";
import Header from "../../../../Components/Header";
import Form from "../../../../Components/Form";
import Loadercomponent from "../../../../Components/Loader";
import { convert_to_file } from "../../../../Controllers/Global";

const ProjectsSlidersForm = () => {
  let { id } = useParams();

  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const slider = id
        ? await update_project_slider({ id: id, ...data?.data })
        : await create_project_slider(data?.data);

      if (slider?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(slider?.message);
        setButtonLoader(false);
      } else {
        toast?.error(slider?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = async (slider) => {
    let image = slider?.image ? slider?.image : "";
    let file = await convert_to_file(image);
    let name = slider?.name ? slider?.name : "";
    let status = slider?.status ? slider?.status : 0;

    forms[0].options[0].form.value = [file];
    forms[0].options[1].form.value = name;
    forms[0].options[2].form.value = status;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      headers.navigate.path[1].name = "Edit";

      const data = {
        id: id,
      };

      const slider = await get_project_slider(data);

      if (slider?.status) {
        await handleData(slider?.data);
      } else {
        toast?.error(slider?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [buttonLoader]);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Form
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default ProjectsSlidersForm;
