import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import tables from "../../../../Data/Tables/Users/Users/tables";
import headers from "../../../../Data/Tables/Users/Users/headers";
import { get_all_users } from "../../../../Controllers/Users";
import Header from "../../../../Components/Header";
import Loadercomponent from "../../../../Components/Loader";
import Table from "../../../../Components/Table";
import Status from "../../../../Components/Status";
import moment from "moment";

const UsersTable = () => {
  const [loader, setLoader] = useState(false);
  const [body, setBody] = useState();

  const formdata = (data) => {
    fetchData(data);
  };

  const actiondata = (data) => {
    fetchData(data);
  };

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const params = {
        search: tabledata?.search,
        sort: tabledata?.sort,
        status: tabledata?.status,
      };

      const customers = await get_all_users(params);

      if (customers?.status) {
        let data = [];

        customers?.data?.map((value, index) => {
          data?.push({
            Name: value?.name,
            Email: value?.email,
            Phone: value?.phone,
            Country: value?.country,
            Login: moment(value?.login).format("MMMM Do YYYY, h:mm:ss a"),
            Logout: moment(value?.logout).format("MMMM Do YYYY, h:mm:ss a"),
            Status: <Status status={value?.status} />,
          });
        });

        setBody(data);
        setLoader(false);
      } else {
        toast?.error(customers?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <Table head={tables} body={body} />
      </Loadercomponent>
    </Header>
  );
};

export default UsersTable;
