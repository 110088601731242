const forms = [
  {
    name: "Home",
    options: [
      {
        name: "Sliders",
        type: "selectandadd",
        path: "/app/home/sliders/add",
        form: {
          name: "sliders",
          mode: "multiple",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Sliders",
          api: "get-all-home-sliders",
          label: "title",
          options: null,
          value: null,
          required: true,
        },
      },
      {
        name: "About title",
        type: "input",
        form: {
          type: "text",
          name: "about_title",
          placeholder: "About title",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "About description",
        type: "input",
        form: {
          type: "text",
          name: "about_description",
          placeholder: "About description",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Project title",
        type: "input",
        form: {
          type: "text",
          name: "project_title",
          placeholder: "Project title",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Projects",
        type: "selectandadd",
        path: "/app/projects/add",
        form: {
          name: "projects",
          mode: "multiple",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Project",
          api: "get-all-projects",
          options: null,
          value: null,
          required: true,
        },
      },
      {
        name: "File (Vedio)",
        type: "upload",
        form: {
          type: "text",
          name: "image",
          placeholder: "Recomended (1920 x 1920)",
          accept: {
            "video/mp4": [],
          },
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Features",
        type: "selectandadd",
        path: "/app/home/features/add",
        form: {
          name: "features",
          mode: "multiple",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Features",
          api: "get-all-home-features",
          label: "title",
          options: null,
          value: null,
          required: true,
        },
      },
      {
        name: "Development title",
        type: "input",
        form: {
          type: "text",
          name: "development_title",
          placeholder: "Development title",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Developments",
        type: "selectandadd",
        path: "/app/projects/add",
        form: {
          name: "developments",
          mode: "multiple",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Developments",
          api: "get-all-projects",
          options: null,
          value: null,
          required: true,
        },
      },
      {
        name: "Services title",
        type: "input",
        form: {
          type: "text",
          name: "services_title",
          placeholder: "Services title",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Services",
        type: "selectandadd",
        path: "/app/home/services/add",
        form: {
          name: "services",
          mode: "multiple",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Services",
          api: "get-all-home-services",
          label: "title",
          options: null,
          value: null,
          required: true,
        },
      },
      {
        name: "Testimonials title",
        type: "input",
        form: {
          type: "text",
          name: "testimonials_title",
          placeholder: "Testimonials title",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Testimonials",
        type: "selectandadd",
        path: "/app/home/testimonials/add",
        form: {
          name: "testimonials",
          mode: "multiple",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Testimonials",
          api: "get-all-home-testimonials",
          options: null,
          value: null,
          required: true,
        },
      },
      {
        name: "Blogs title",
        type: "input",
        form: {
          type: "text",
          name: "blogs_title",
          placeholder: "Blogs title",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Blogs",
        type: "selectandadd",
        path: "/app/home/testimonials/add",
        form: {
          name: "blogs",
          mode: "multiple",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Blogs",
          api: "get-all-blogs",
          label: "title",
          options: null,
          value: null,
          required: true,
        },
      },
      {
        name: "Status",
        type: "select",
        form: {
          name: "status",
          allowClear: false,
          placeholder: "Status",
          value: 1,
          options: [
            {
              value: 1,
              label: "Active",
            },
            {
              value: 0,
              label: "In-active",
            },
          ],
          required: false,
        },
      },
    ],
  },
];

export default forms;
