import { Spin } from "antd";
import React from "react";

const Formcomponent = (props) => {
  const { form, onReset, onSubmit, loader } = props;

  return loader ? (
    <div className="flex justify-center items-center h-[60vh]">
      <Spin tip="Loading" size="large" />
    </div>
  ) : (
    <form
      className={
        form?.[0]?.className
          ? form?.[0]?.className
          : "bg-white p-4 rounded-md p-8 w-[50%] mx-auto"
      }
      onReset={onReset}
      onSubmit={onSubmit}
    >
      {props?.children}
    </form>
  );
};

export default Formcomponent;
