import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Components/Form";
import toast from "react-hot-toast";
import Header from "../../../../Components/Header";
import {
  create_blog,
  get_blog,
  update_blog,
} from "../../../../Controllers/Blogs/Blogs";
import forms from "../../../../Data/Forms/Blogs/Blogs/forms";
import headers from "../../../../Data/Forms/Blogs/Blogs/headers";
import Loadercomponent from "../../../../Components/Loader";
import AddButton from "../../../../Components/Buttons/add";
import RemoveButton from "../../../../Components/Buttons/delete";
import { convert_to_file } from "../../../../Controllers/Global";

const BlogsForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const blog = id
        ? await update_blog({ ...data?.data, id: id })
        : await create_blog(data?.data);

      if (blog?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(blog?.message);
        setButtonLoader(false);
      } else {
        toast?.error(blog?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = async (blog) => {
    let image = blog?.image ? blog?.image : "";
    let file = await convert_to_file(image);
    let title = blog?.title ? blog?.title : "";
    let description = blog?.description ? blog?.description : "";
    let date = blog?.date ? blog?.date : "";
    let author = blog?.author ? blog?.author : "";
    let recomended = blog?.recomended ? JSON?.parse(blog?.recomended) : null;
    let status = blog?.status ? blog?.status : 0;

    let recomended_options =
      blog?.recomended_options?.length > 0 ? blog?.recomended_options : null;

    forms[0].options[0].form.value = [file];
    forms[0].options[1].form.value = title;
    forms[0].options[2].form.value = description;
    forms[0].options[3].form.value = date;
    forms[0].options[4].form.value = author;
    forms[0].options[5].form.value = recomended;
    forms[0].options[5].form.options = recomended_options;
    forms[0].options[6].form.value = status;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const blog = await get_blog(data);
      if (blog?.status) {
        handleData(blog?.data);
      } else {
        toast?.error(blog?.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default BlogsForm;
