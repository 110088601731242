import React from "react";

const Formdata = (props) => {
  const { value } = props;

  return (
    <div
      className={
        value?.formdata?.className
          ? value?.formdata?.className
          : "border text-sm text-medium text-gray-500 rounded-md p-4"
      }
    >
      {props?.children}
    </div>
  );
};
export default Formdata;
