const headers = {
  name: "Features",
  navigate: {
    path: [
      {
        name: "Features",
        path: "/app/home/features",
      },
      {
        name: "Add",
        path: "",
      },
    ],
  },
};

export default headers;
