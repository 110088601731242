import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";
import { json } from "react-router-dom";

const create_milestone = async (milestone) => {
  try {
    let data = {
      title: milestone?.title,
      description: milestone?.description,
      status: milestone?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-milestone`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const update_milestone = async (milestone) => {
  try {
    let data = {
      id: milestone?.id,
      title: milestone?.title,
      description: milestone?.description,
      status: milestone?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-milestone`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_milestone = async (milestone) => {
  try {
    const data = {
      id: milestone?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-milestone`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_all_milestones = async (milestone) => {
  try {
    const data = {
      search: milestone?.search,
      sort: milestone?.sort,
      unit: milestone?.unit,
      category: milestone?.category,
      brand: milestone?.brand,
      type: milestone?.type,
      status: milestone?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-milestones `,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

export {
  create_milestone,
  update_milestone,
  get_milestone,
  get_all_milestones,
};
