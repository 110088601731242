import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import tables from "../../../../Data/Tables/Home/Home/tables";
import headers from "../../../../Data/Tables/Home/Home/headers";
import { get_all_homes } from "../../../../Controllers/Home/Home";
import Header from "../../../../Components/Header";
import Loadercomponent from "../../../../Components/Loader";
import Table from "../../../../Components/Table";
import Status from "../../../../Components/Status";
import ActionButton from "../../../../Components/Buttons/action";

const HomeTable = () => {
  const [loader, setLoader] = useState(false);
  const [body, setBody] = useState();

  const formdata = (data) => {
    fetchData(data);
  };

  const actiondata = (data) => {
    fetchData(data);
  };

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const params = {
        search: tabledata?.search,
        sort: tabledata?.sort,
        status: tabledata?.status,
      };

      const homes = await get_all_homes(params);

      if (homes?.status) {
        let data = [];

        homes?.data?.map((value, index) => {
          data?.push({
            Name: "Home",
            Action: (
              <ActionButton
                edit={`/app/home/edit/${value?._id}`}
                id={value?._id}
                // api="delete-contact"
                actiondata={actiondata}
              />
            ),
          });
        });

        setBody(data);
        setLoader(false);
      } else {
        toast?.error(homes?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <Table head={tables} body={body} />
      </Loadercomponent>
    </Header>
  );
};

export default HomeTable;
