const forms = [
  {
    name: "About",
    options: [
      {
        name: "Image",
        type: "upload",
        form: {
          type: "text",
          name: "image",
          placeholder: "Recomended (462 X 640)",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Title",
        type: "input",
        form: {
          type: "text",
          name: "title",
          placeholder: "Title",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Description",
        type: "input",
        form: {
          type: "text",
          name: "description",
          placeholder: "Description",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Projects",
        type: "input",
        form: {
          type: "text",
          name: "projects",
          placeholder: "Total Projects",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Area",
        type: "input",
        form: {
          type: "text",
          name: "area",
          placeholder: "Developed Area",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Ongoing Projects",
        type: "input",
        form: {
          type: "text",
          name: "ongoing",
          placeholder: "Ongoing Projects",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Units",
        type: "input",
        form: {
          type: "text",
          name: "units",
          placeholder: "Units Sold",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Milestones",
        type: "selectandadd",
        path: "/app/about/milestones/add",
        form: {
          mode: "multiple",
          name: "milestones",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Milestones",
          api: "get-all-milestones",
          label: "title",
          options: null,
          value: null,
          required: true,
        },
      },
      {
        name: "Teams",
        type: "selectandadd",
        path: "/app/contacts/teams/add",
        form: {
          mode: "multiple",
          name: "teams",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Teams",
          api: "get-all-teams",
          options: null,
          value: null,
          required: true,
        },
      },
    ],
  },
];

export default forms;
