const headers = {
  name: "Candidates",
  components: [
    {
      type: "search",
      name: "search",
      placeholder: "Search",
      value: "",
    },
    {
      type: "select",
      name: "sort",
      placeholder: "Sort",
      allowClear: true,
      showSearch: false,
      filterOption: true,
      value: null,
      options: [
        { value: 0, label: "Name (A-Z)" },
        { value: 1, label: "Name (Z-A)" },
      ],
    },
    {
      type: "filter",
      name: "status",
      placeholder: "Status",
      value: null,
      allowClear: true,
      showSearch: false,
      filterOption: true,
      options: [
        { value: 0, label: "Inactive" },
        { value: 1, label: "Active" },
      ],
    },
    {
      type: "add",
      path: "/app/careers/candidates/add",
    },
  ],
  navigate: {
    tabs: [
      {
        name: "Careers",
        path: "/app/careers",
      },
      {
        name: "Candidates",
        path: "",
      },
      {
        name: "Brokers",
        path: "/app/careers/brokers",
      },
    ],
    total: [
      { value: 0, label: "Total" },
      { value: 0, label: "Active" },
      { value: 0, label: "Inactive" },
    ],
  },
};

export default headers;
