import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_candidate = async (candidate) => {
  try {
    let data = new FormData();
    data.append("image", candidate?.image);
    data.append("name", candidate?.name);
    data.append("phone", candidate?.phone);
    data.append("email", candidate?.email);
    data.append("experience", candidate?.experience);
    data.append("natonality", candidate?.natonality);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-candidate`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const update_candidate = async (candidate) => {
  try {
    let data = new FormData();
    data.append("id", candidate?.id);
    data.append("image", candidate?.image);
    data.append("name", candidate?.name);
    data.append("phone", candidate?.phone);
    data.append("email", candidate?.email);
    data.append("experience", candidate?.experience);
    data.append("natonality", candidate?.natonality);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-candidate`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_candidate = async (candidate) => {
  try {
    const data = {
      id: candidate?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-candidate`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_all_candidates = async (candidate) => {
  try {
    const data = {
      search: candidate?.search,
      sort: candidate?.sort,
      unit: candidate?.unit,
      category: candidate?.category,
      brand: candidate?.brand,
      type: candidate?.type,
      status: candidate?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-candidates `,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

export {
  create_candidate,
  update_candidate,
  get_candidate,
  get_all_candidates,
};
