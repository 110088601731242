import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_team = async (team) => {
  try {
    let data = new FormData();
    data.append("image", team?.image[0]);
    data.append("name", team?.name);
    data.append("designation", team?.designation);
    data.append("phone", team?.phone);
    data.append("email", team?.email);
    data.append("description", team?.description);
    data.append("status", team?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/create-team`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const update_team = async (team) => {
  try {
    let data = new FormData();
    data.append("id", team?.id);
    data.append("image", team?.image[0]);
    data.append("name", team?.name);
    data.append("designation", team?.designation);
    data.append("phone", team?.phone);
    data.append("email", team?.email);
    data.append("description", team?.description);
    data.append("status", team?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/update-team`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_team = async (team) => {
  try {
    const data = {
      id: team?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-team`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_all_teams = async (team) => {
  try {
    const data = {
      search: team?.search,
      sort: team?.sort,
      unit: team?.unit,
      category: team?.category,
      brand: team?.brand,
      type: team?.type,
      status: team?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-all-teams`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

export { create_team, update_team, get_team, get_all_teams };
