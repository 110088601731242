import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Components/Form";
import toast from "react-hot-toast";
import Header from "../../../../Components/Header";
import {
  create_home_service,
  get_home_service,
  update_home_service,
} from "../../../../Controllers/Home/Services";
import forms from "../../../../Data/Forms/Home/Services/forms";
import headers from "../../../../Data/Forms/Home/Services/headers";
import Loadercomponent from "../../../../Components/Loader";
import AddButton from "../../../../Components/Buttons/add";
import RemoveButton from "../../../../Components/Buttons/delete";
import { convert_to_file } from "../../../../Controllers/Global";

const HomeServicesForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const service = id
        ? await update_home_service({ ...data?.data, id: id })
        : await create_home_service(data?.data);

      if (service?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(service?.message);
        setButtonLoader(false);
      } else {
        toast?.error(service?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = async (service) => {
    let image = service?.image ? service?.image : "";
    let file = await convert_to_file(image);
    let title = service?.title ? service?.title : "";
    let description = service?.description ? service?.description : "";
    let status = service?.status ? service?.status : 0;

    forms[0].options[0].form.value = [file];
    forms[0].options[1].form.value = title;
    forms[0].options[2].form.value = description;
    forms[0].options[3].form.value = status;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const service = await get_home_service(data);
      if (service?.status) {
        handleData(service?.data);
      } else {
        toast?.error(service?.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default HomeServicesForm;
