import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Components/Form";
import toast from "react-hot-toast";
import Header from "../../../../Components/Header";
import {
  create_career,
  get_career,
  update_career,
} from "../../../../Controllers/Careers/Careers";
import forms from "../../../../Data/Forms/Careers/Careers/forms";
import headers from "../../../../Data/Forms/Careers/Careers/headers";
import Loadercomponent from "../../../../Components/Loader";
import AddButton from "../../../../Components/Buttons/add";
import RemoveButton from "../../../../Components/Buttons/delete";

const CareersForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const career = id
        ? await update_career({ ...data?.data, id: id, image: "" })
        : await create_career(data?.data);

      if (career?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(career?.message);
        setButtonLoader(false);
      } else {
        toast?.error(career?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = (career) => {
    let date = career?.date ? career?.date : "";
    let role = career?.role ? career?.role : "";
    let description = career?.description ? career?.description : "";
    let status = career?.status ? career?.status : 0;

    values[0].options[0].form.value = date;
    values[0].options[1].form.value = role;
    values[0].options[2].form.value = description;
    values[0].options[3].form.value = status;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const career = await get_career(data);
      if (career?.status) {
        handleData(career?.data);
      } else {
        toast?.error(career?.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default CareersForm;
