import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutButton from "../Buttons/logout";
import HomeIcon from "../../Assets/Svg/home";

const Sidebar = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const data = [
    {
      // icon: <HomeIcon />,
      name: "Home",
      path: "/app/home",
    },
    {
      name: "About",
      path: "/app/about",
    },
    {
      name: "Projects",
      path: "/app/projects",
    },
    {
      name: "Blogs",
      path: "/app/blogs",
    },
    {
      name: "Careers",
      path: "/app/careers",
    },
    {
      name: "Contacts",
      path: "/app/contacts",
    },
    {
      name: "Enquiry",
      path: "/app/enquiry",
    },
    // {
    //   name: "Bookings",
    //   path: "/app/bookings",
    // },
    // {
    //   name: "Users",
    //   path: "/app/users",
    // },

    // {
    //   name: "Settings",
    //   path: "/app/settings",
    // },
  ];

  return (
    <div className="border-r bg-white fixed left-0 top-0 bottom-0 z-50 w-64">
      <div className="p-4 overflow-y-auto h-full">
        <h2 className="text-lg  mb-4">Sidebar</h2>
        <div className="pt-4">
          {data?.map((value, index) => {
            return (
              <div
                className={
                  location?.pathname?.includes(value?.path)
                    ? "my-2 cursor-pointer bg-[#2D53DA] rounded-lg"
                    : "my-2 cursor-pointer"
                }
                onClick={() => navigate(value?.path)}
              >
                <button
                  className={
                    !location?.pathname?.includes(value?.path)
                      ? "w-full rounded-lg focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
                      : "w-full focus:outline-none"
                  }
                >
                  <div
                    className={
                      location?.pathname?.includes(value?.path)
                        ? "flex p-2 text-white"
                        : "flex p-2 text-[#353F4F] hover:text-[#2D53DA]"
                    }
                  >
                    <div>{value?.icon}</div>
                    <div className="px-2 my-auto">{value?.name}</div>
                  </div>
                </button>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-full absolute bottom-0 left-0">
        <LogoutButton />
      </div>
    </div>
  );
};

export default Sidebar;
