const headers = {
  name: "Brokers",
  navigate: {
    path: [
      {
        name: "Brokers",
        path: "/app/careers/brokers",
      },
      {
        name: "Add",
        path: "",
      },
    ],
  },
};

export default headers;
