const headers = {
  name: "Project types",
  navigate: {
    path: [
      {
        name: "Types",
        path: "/app/projects/types",
      },
      {
        name: "Add",
        path: "",
      },
    ],
  },
};

export default headers;
