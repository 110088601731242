import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";
import { json } from "react-router-dom";

const create_home_testimonial = async (home_testimonial) => {
  try {
    let data = new FormData();
    data.append("image", home_testimonial?.image[0]);
    data.append("name", home_testimonial?.name);
    data.append("description", home_testimonial?.description);
    data.append("address", home_testimonial?.address);
    data.append("status", home_testimonial?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-home-testimonial`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const update_home_testimonial = async (home_testimonial) => {
  try {
    let data = new FormData();
    data.append("id", home_testimonial?.id);
    data.append("image", home_testimonial?.image[0]);
    data.append("name", home_testimonial?.name);
    data.append("description", home_testimonial?.description);
    data.append("address", home_testimonial?.address);
    data.append("status", home_testimonial?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-home-testimonial`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_home_testimonial = async (home_testimonial) => {
  try {
    const data = {
      id: home_testimonial?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-home-testimonial`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_all_home_testimonials = async (home_testimonial) => {
  try {
    const data = {
      search: home_testimonial?.search,
      sort: home_testimonial?.sort,
      unit: home_testimonial?.unit,
      category: home_testimonial?.category,
      brand: home_testimonial?.brand,
      type: home_testimonial?.type,
      status: home_testimonial?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-home-testimonials `,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

export {
  create_home_testimonial,
  update_home_testimonial,
  get_home_testimonial,
  get_all_home_testimonials,
};
