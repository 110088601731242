import { createBrowserRouter } from "react-router-dom";
import SigninForm from "../Pages/Signin/Form";
import Layout from "../Components/Layout";
import Settings from "../Pages/Settings/Settings";
import SignupForm from "../Pages/Signup/Form";
import UsersTable from "../Pages/Users/Users/Table";
import ProjectsTable from "../Pages/Projects/Projects/Table";
import ProjectsForm from "../Pages/Projects/Projects/Form";
import ProjectsSlidersTable from "../Pages/Projects/Sliders/Table";
import ProjectsSlidersForm from "../Pages/Projects/Sliders/Form";
import ProjectsGalleryForm from "../Pages/Projects/Gallery/Form";
import ProjectsGalleryTable from "../Pages/Projects/Gallery/Table";
import ProjectAmenitiesTable from "../Pages/Projects/Amenities/Table";
import ProjectAmenitiesForm from "../Pages/Projects/Amenities/Form";
import ProjectNearbyTable from "../Pages/Projects/Nearby/Table";
import ProjectNearbyForm from "../Pages/Projects/Nearby/Form";
import ProjectTypesTable from "../Pages/Projects/Types/Table";
import ProjectTypesForm from "../Pages/Projects/Types/Form";
import ProjectPlansTable from "../Pages/Projects/Plans/Table";
import ProjectPlansForm from "../Pages/Projects/Plans/Form";
import BlogsTable from "../Pages/Blogs/Blogs/Table";
import BlogsForm from "../Pages/Blogs/Blogs/Form";
import ContactsForm from "../Pages/Contacts/Contacts/Form";
import ContactsTable from "../Pages/Contacts/Contacts/Table";
import ContactBrokersTable from "../Pages/Contacts/Brokers/Table";
import ContactBrokersForm from "../Pages/Contacts/Brokers/Form";
import ContactTeamsTable from "../Pages/Contacts/Teams/Table";
import ContactTeamsForm from "../Pages/Contacts/Teams/Form";
import CareersForm from "../Pages/Careers/Careers/Form";
import CareersTable from "../Pages/Careers/Careers/Table";
import CareerCandidatesTable from "../Pages/Careers/Candidates/Table";
import CareerCandidatesForm from "../Pages/Careers/Candidates/Form";
import CareerBrokersTable from "../Pages/Careers/Brokers/Table";
import CareerBrokersForm from "../Pages/Careers/Brokers/Form";
import AboutTable from "../Pages/About/About/Table";
import AboutForm from "../Pages/About/About/Form";
import AboutMilestonesTable from "../Pages/About/Milestones/Table";
import AboutMilestonesForm from "../Pages/About/Milestones/Form";
import HomeTable from "../Pages/Home/Home/Table";
import HomeForm from "../Pages/Home/Home/Form";
import HomeSliderTable from "../Pages/Home/Sliders/Table";
import HomeSliderForm from "../Pages/Home/Sliders/Form";
import HomeFeatureTable from "../Pages/Home/Features/Table";
import HomeFeaturesForm from "../Pages/Home/Features/Form";
import HomeServicesTable from "../Pages/Home/Services/Table";
import HomeServicesForm from "../Pages/Home/Services/Form";
import HomeTestimonialsTable from "../Pages/Home/Testimonials/Table";
import HomeTestimonialsForm from "../Pages/Home/Testimonials/Form";
import EnquiryTable from "../Pages/Enquiry/Enquiry/Table";

const router = createBrowserRouter([
  {
    path: "/",
    element: <SigninForm />,
  },
  {
    path: "/signup",
    element: <SignupForm />,
  },
  {
    path: "/app",
    element: <Layout />,
    children: [
      {
        path: "/app/home",
        element: <HomeTable />,
      },
      {
        path: "/app/home/add",
        element: <HomeForm />,
      },
      {
        path: "/app/home/edit/:id",
        element: <HomeForm />,
      },
      {
        path: "/app/home/sliders",
        element: <HomeSliderTable />,
      },
      {
        path: "/app/home/sliders/add",
        element: <HomeSliderForm />,
      },
      {
        path: "/app/home/sliders/edit/:id",
        element: <HomeSliderForm />,
      },
      {
        path: "/app/home/features",
        element: <HomeFeatureTable />,
      },
      {
        path: "/app/home/features/add",
        element: <HomeFeaturesForm />,
      },
      {
        path: "/app/home/features/edit/:id",
        element: <HomeFeaturesForm />,
      },
      {
        path: "/app/home/services",
        element: <HomeServicesTable />,
      },
      {
        path: "/app/home/services/add",
        element: <HomeServicesForm />,
      },
      {
        path: "/app/home/services/edit/:id",
        element: <HomeServicesForm />,
      },
      {
        path: "/app/home/testimonials",
        element: <HomeTestimonialsTable />,
      },
      {
        path: "/app/home/testimonials/add",
        element: <HomeTestimonialsForm />,
      },
      {
        path: "/app/home/testimonials/edit/:id",
        element: <HomeTestimonialsForm />,
      },

      {
        path: "/app/about",
        element: <AboutTable />,
      },
      {
        path: "/app/about/add",
        element: <AboutForm />,
      },
      {
        path: "/app/about/edit/:id",
        element: <AboutForm />,
      },
      {
        path: "/app/about/milestones",
        element: <AboutMilestonesTable />,
      },
      {
        path: "/app/about/milestones/add",
        element: <AboutMilestonesForm />,
      },
      {
        path: "/app/about/milestones/edit/:id",
        element: <AboutMilestonesForm />,
      },

      {
        path: "/app/projects",
        element: <ProjectsTable />,
      },
      {
        path: "/app/projects/add",
        element: <ProjectsForm />,
      },
      {
        path: "/app/projects/edit/:id",
        element: <ProjectsForm />,
      },
      {
        path: "/app/projects/sliders",
        element: <ProjectsSlidersTable />,
      },
      {
        path: "/app/projects/sliders/add",
        element: <ProjectsSlidersForm />,
      },
      {
        path: "/app/projects/sliders/edit/:id",
        element: <ProjectsSlidersForm />,
      },
      {
        path: "/app/projects/gallery",
        element: <ProjectsGalleryTable />,
      },
      {
        path: "/app/projects/gallery/add",
        element: <ProjectsGalleryForm />,
      },
      {
        path: "/app/projects/gallery/edit/:id",
        element: <ProjectsGalleryForm />,
      },
      {
        path: "/app/projects/amenities",
        element: <ProjectAmenitiesTable />,
      },
      {
        path: "/app/projects/amenities/add",
        element: <ProjectAmenitiesForm />,
      },
      {
        path: "/app/projects/amenities/edit/:id",
        element: <ProjectAmenitiesForm />,
      },
      {
        path: "/app/projects/nearby",
        element: <ProjectNearbyTable />,
      },
      {
        path: "/app/projects/nearby/add",
        element: <ProjectNearbyForm />,
      },
      {
        path: "/app/projects/nearby/edit/:id",
        element: <ProjectNearbyForm />,
      },
      {
        path: "/app/projects/types",
        element: <ProjectTypesTable />,
      },
      {
        path: "/app/projects/types/add",
        element: <ProjectTypesForm />,
      },
      {
        path: "/app/projects/types/edit/:id",
        element: <ProjectTypesForm />,
      },
      {
        path: "/app/projects/plans",
        element: <ProjectPlansTable />,
      },
      {
        path: "/app/projects/plans/add",
        element: <ProjectPlansForm />,
      },
      {
        path: "/app/projects/plans/edit/:id",
        element: <ProjectPlansForm />,
      },

      {
        path: "/app/blogs",
        element: <BlogsTable />,
      },
      {
        path: "/app/blogs/add",
        element: <BlogsForm />,
      },
      {
        path: "/app/blogs/edit/:id",
        element: <BlogsForm />,
      },

      {
        path: "/app/careers",
        element: <CareersTable />,
      },
      {
        path: "/app/careers/add",
        element: <CareersForm />,
      },
      {
        path: "/app/careers/edit/:id",
        element: <CareersForm />,
      },
      {
        path: "/app/careers/candidates",
        element: <CareerCandidatesTable />,
      },
      {
        path: "/app/careers/candidates/add",
        element: <CareerCandidatesForm />,
      },
      {
        path: "/app/careers/candidates/edit/:id",
        element: <CareerCandidatesForm />,
      },
      {
        path: "/app/careers/brokers",
        element: <CareerBrokersTable />,
      },
      {
        path: "/app/careers/brokers/add",
        element: <CareerBrokersForm />,
      },
      {
        path: "/app/careers/brokers/edit/:id",
        element: <CareerBrokersForm />,
      },

      {
        path: "/app/contacts",
        element: <ContactsTable />,
      },
      {
        path: "/app/contacts/add",
        element: <ContactsForm />,
      },
      {
        path: "/app/contacts/edit/:id",
        element: <ContactsForm />,
      },
      {
        path: "/app/contacts/teams",
        element: <ContactTeamsTable />,
      },
      {
        path: "/app/contacts/teams/add",
        element: <ContactTeamsForm />,
      },
      {
        path: "/app/contacts/teams/edit/:id",
        element: <ContactTeamsForm />,
      },
      {
        path: "/app/contacts/brokers",
        element: <ContactBrokersTable />,
      },
      {
        path: "/app/contacts/brokers/add",
        element: <ContactBrokersForm />,
      },
      {
        path: "/app/contacts/brokers/edit/:id",
        element: <ContactBrokersForm />,
      },

      {
        path: "/app/enquiry",
        element: <EnquiryTable />,
      },

      {
        path: "/app/bookings",
        element: <UsersTable />,
      },
      {
        path: "/app/users",
        element: <UsersTable />,
      },

      {
        path: "/app/settings",
        element: <Settings />,
      },
    ],
  },
]);

export default router;
