const headers = {
  name: "Project sliders",
  navigate: {
    path: [
      {
        name: "Sliders",
        path: "/app/projects/sliders",
      },
      {
        name: "Add",
        path: "",
      },
    ],
  },
};

export default headers;
