import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import tables from "../../../../Data/Tables/Careers/Careers/tables";
import headers from "../../../../Data/Tables/Careers/Careers/headers";
import { get_all_careers } from "../../../../Controllers/Careers/Careers";
import Header from "../../../../Components/Header";
import Loadercomponent from "../../../../Components/Loader";
import Table from "../../../../Components/Table";
import Status from "../../../../Components/Status";
import ActionButton from "../../../../Components/Buttons/action";
import moment from "moment";

const CareersTable = () => {
  const [loader, setLoader] = useState(false);
  const [body, setBody] = useState();

  const formdata = (data) => {
    fetchData(data);
  };

  const actiondata = (data) => {
    fetchData(data);
  };

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const params = {
        search: tabledata?.search,
        sort: tabledata?.sort,
        status: tabledata?.status,
      };

      const careers = await get_all_careers(params);

      if (careers?.status) {
        let data = [];

        careers?.data?.map((value, index) => {
          data?.push({
            Date: moment(value?.date).format("Do MMMM YYYY"),
            Role: value?.role,
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton
                edit={`/app/careers/edit/${value?._id}`}
                id={value?._id}
                api="delete-careers"
                actiondata={actiondata}
              />
            ),
          });
        });

        setBody(data);
        setLoader(false);
      } else {
        toast?.error(careers?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <Table head={tables} body={body} />
      </Loadercomponent>
    </Header>
  );
};

export default CareersTable;
