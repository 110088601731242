const tables = [
  {
    name: "Name",
  },
  {
    name: "Phone",
  },
  {
    name: "Email",
  },
  {
    name: "Type",
  },
  {
    name: "Created",
  },
];

export default tables;
