const forms = [
  {
    name: "Services",
    options: [
      {
        name: "Image",
        type: "upload",
        form: {
          type: "text",
          name: "image",
          placeholder: "Recomended (150 X 150)",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Title",
        type: "input",
        form: {
          type: "text",
          name: "title",
          placeholder: "Title",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Description",
        type: "input",
        form: {
          type: "text",
          name: "description",
          placeholder: "Description",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Status",
        type: "select",
        form: {
          name: "status",
          allowClear: false,
          placeholder: "Status",
          value: 1,
          options: [
            {
              value: 1,
              label: "Active",
            },
            {
              value: 0,
              label: "In-active",
            },
          ],
          required: false,
        },
      },
    ],
  },
];

export default forms;
