import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";
import { json } from "react-router-dom";

const create_about = async (about) => {
  try {
    let data = new FormData();
    data.append("image", about?.image[0]);
    data.append("title", about?.title);
    data.append("description", about?.description);
    data.append("projects", about?.projects);
    data.append("area", about?.area);
    data.append("ongoing", about?.ongoing);
    data.append("units", about?.units);
    data.append("milestones", JSON?.stringify(about?.milestones));
    data.append("teams", JSON?.stringify(about?.teams));

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/create-about`, data, config);
    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const update_about = async (about) => {
  try {
    let data = new FormData();
    data.append("id", about?.id);
    data.append("image", about?.image[0]);
    data.append("title", about?.title);
    data.append("description", about?.description);
    data.append("projects", about?.projects);
    data.append("area", about?.area);
    data.append("ongoing", about?.ongoing);
    data.append("units", about?.units);
    data.append("milestones", JSON?.stringify(about?.milestones));
    data.append("teams", JSON?.stringify(about?.teams));

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/update-about`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_about = async (about) => {
  try {
    const data = {
      id: about?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-about`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_all_abouts = async (about) => {
  try {
    const data = {
      search: about?.search,
      sort: about?.sort,
      unit: about?.unit,
      category: about?.category,
      brand: about?.brand,
      type: about?.type,
      status: about?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-abouts `,
      data,
      config
    );

    console.log("edwddwdwd", response);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

export { create_about, update_about, get_about, get_all_abouts };
