import React from "react";
import axios from "axios";
import APIURL from "../../Main/apiurl";

const Signup = async (signup) => {
  try {
    const data = {
      name: signup?.name,
      phone: signup?.phone,
      email: signup?.email,
      password: signup?.password,
      company: signup?.company,
      country: signup?.country,
    };

    const response = await axios.post(`${APIURL}/signup-admin`, data);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const Signin = async (signup) => {
  try {
    const data = {
      email: signup?.email,
      password: signup?.password,
    };

    const response = await axios.post(`${APIURL}/signin-admin`, data);

    if (response?.data?.status) {
      localStorage.setItem("user", response?.data?.data?.token);
    }

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const Signout = async (signout) => {
  try {
    const data = {};

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/signout-admin`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (errors) {
    return errors;
  }
};

export { Signup, Signin, Signout };
