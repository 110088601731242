import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Components/Form";
import toast from "react-hot-toast";
import Header from "../../../../Components/Header";
import {
  create_contact,
  get_contact,
  update_contact,
} from "../../../../Controllers/Contacts/Contacts";
import forms from "../../../../Data/Forms/Contacts/Contacts/forms";
import headers from "../../../../Data/Forms/Contacts/Contacts/headers";
import Loadercomponent from "../../../../Components/Loader";
import AddButton from "../../../../Components/Buttons/add";
import RemoveButton from "../../../../Components/Buttons/delete";

const ContactsForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const contact = id
        ? await update_contact({ ...data?.data, id: id })
        : await create_contact(data?.data);

      if (contact?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(contact?.message);
        setButtonLoader(false);
      } else {
        toast?.error(contact?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = (contact) => {
    let area = contact?.area ? contact?.area : "";
    let city = contact?.city ? contact?.city : "";
    let state = contact?.state ? contact?.state : "";
    let country = contact?.country ? contact?.country : "";
    let email = contact?.email ? contact?.email : "";
    let phone = contact?.phone ? contact?.phone : "";
    let location = contact?.location ? contact?.location : "";
    let teams = contact?.teams ? JSON?.parse(contact?.teams) : "";
    let brokers = contact?.brokers ? JSON?.parse(contact?.brokers) : "";

    let team_options =
      contact?.team_options?.length > 0 ? contact?.team_options : null;
    let broker_options =
      contact?.broker_options?.length > 0 ? contact?.broker_options : null;

    values[0].options[0].form.value = area;
    values[0].options[1].form.value = city;
    values[0].options[2].form.value = state;
    values[0].options[3].form.value = country;
    values[0].options[4].form.value = email;
    values[0].options[5].form.value = phone;
    values[0].options[6].form.value = location;
    values[0].options[7].form.value = teams;
    values[0].options[7].form.options = team_options;
    values[0].options[8].form.value = brokers;
    values[0].options[8].form.options = broker_options;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const contact = await get_contact(data);
      if (contact?.status) {
        handleData(contact?.data);
      } else {
        toast?.error(contact?.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default ContactsForm;
