const forms = [
  {
    name: "Plan",
    options: [
      {
        name: "Image",
        type: "upload",
        form: {
          type: "text",
          name: "image",
          placeholder: "Recomended (300 x 250)",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Name",
        type: "input",
        form: {
          type: "text",
          name: "name",
          placeholder: "Plan name",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Description",
        type: "input",
        form: {
          type: "text",
          name: "description",
          placeholder: "Plan description",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Project type",
        type: "selectandadd",
        path: "/app/projects/types/add",
        form: {
          name: "type",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Project type",
          api: "get-all-project-types",
          options: null,
          value: null,
          required: true,
        },
      },
      {
        name: "Total",
        type: "input",
        form: {
          type: "number",
          name: "total",
          placeholder: "Plan total",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Available",
        type: "input",
        form: {
          type: "number",
          name: "available",
          placeholder: "Plan available",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Price start",
        type: "input",
        form: {
          type: "number",
          name: "price_start",
          placeholder: "Price start",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Price end",
        type: "input",
        form: {
          type: "number",
          name: "price_end",
          placeholder: "Price end",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Floors",
        type: "select",
        form: {
          name: "floors",
          mode: "multiple",
          allowClear: true,
          showSearch: true,
          placeholder: "Floors Available",
          value: null,
          options: Array.from({ length: 100 }, (_, i) => ({
            value: i + 1,
            label: i + 1,
          })),
          required: true,
        },
      },
      {
        name: "Status",
        type: "select",
        form: {
          name: "status",
          allowClear: false,
          placeholder: "Status",
          value: 1,
          options: [
            {
              value: 1,
              label: "Active",
            },
            {
              value: 0,
              label: "In-active",
            },
          ],
          required: false,
        },
      },
    ],
  },
];

export default forms;
