import { DatePicker } from "antd";
import dayjs from "dayjs";
import React from "react";
import Error from "../Error";

const Datecomponenet = React.forwardRef((props, focus) => {
  const { index, ind, val, form, setForm, getFormdata } = props;

  const handleDate = (e, index, ind) => {
    let data = [...form];
    data[index].options[ind].form.value = e?.$d;

    setForm(data);
    getFormdata(data);
  };

  return (
    <React.Fragment>
      <DatePicker
        key={index?.toString()?.concat(ind)}
        id={index?.toString()?.concat(ind)}
        name={val?.form?.name}
        placeholder={val?.form?.placeholder}
        className={
          val?.form?.className ? val?.form?.className : "text-regular w-full"
        }
        ref={focus.current[val?.form?.name?.concat(index + "" + ind)]}
        size={val?.form?.size}
        value={val?.form?.value && dayjs(val?.form?.value)}
        onChange={(e) => handleDate(e, index, ind)}
        autoComplete="off"
      />
      <Error id={val?.form?.name?.concat(index + "" + ind)} />
    </React.Fragment>
  );
});

export default Datecomponenet;
