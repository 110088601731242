import React from "react";

const Titlecomponent = (props) => {
  const { title, index } = props;

  return (
    <div
      key={index}
      className={index > 0 ? "text-medium py-3" : "text-medium pb-3"}
    >
      {title}
    </div>
  );
};

export default Titlecomponent;
