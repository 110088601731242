const headers = {
  name: "Services",
  navigate: {
    path: [
      {
        name: "Services",
        path: "/app/home/services",
      },
      {
        name: "Add",
        path: "",
      },
    ],
  },
};

export default headers;
