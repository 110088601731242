import { Select } from "antd";
import React from "react";
import Error from "../Error";
import PlusButton from "../Buttons/plus";
import { useNavigate } from "react-router-dom";
import { select_search } from "../../Controllers/Global";

const Selectcomponent = React.forwardRef((props, focus) => {
  const { index, ind, val, form, setForm, getFormdata } = props;

  const handleSelect = (e, index, ind) => {
    let data = [...form];

    data[index].options[ind].form.value = e;

    if (data[index]?.options[ind]?.show?.check) {
      data?.map((value, k) => {
        value?.options?.map((val, i) => {
          if (val?.show?.name == data[index].options[ind].form.name) {
            data[k].options[i].show.status = e ? true : false;
          }
        });
      });
    }

    setForm(data);
    getFormdata(data);
  };

  const handleSearch = async (e, index, ind) => {
    if (val?.form?.api) {
      let data = [...form];

      const select = {
        api: data[index].options[ind].form.api,
        search: e,
        status: 1,
      };
      const search = await select_search(select);

      const key = data[index].options[ind].form.key;
      const label = data[index].options[ind].form.label;

      if (search?.status) {
        let options = [];
        search?.data?.map((value, index) => {
          options?.push({
            value: key ? value?.[key] : value?._id,
            label: label ? value?.[label] : value?.name,
          });
        });

        data[index].options[ind].form.options = options;
        setForm(data);
        getFormdata(data);
      } else {
        getFormdata(data);
      }
    }
  };

  return (
    <React.Fragment>
      <Select
        key={index}
        id={index?.toString()?.concat(ind)}
        name={val?.form?.name}
        placeholder={val?.form?.placeholder}
        className={
          val?.form?.className ? val?.form?.className : "text-regular w-full"
        }
        ref={focus.current[val?.form?.name?.concat(index + "" + ind)]}
        size={val?.form?.size}
        mode={val?.form?.mode}
        allowClear={val?.form?.allowClear}
        showSearch={val?.form?.showSearch}
        filterOption={val?.form?.filterOption}
        options={val?.form?.options}
        value={val?.form?.value}
        onClick={() => handleSearch("", index, ind)}
        onSearch={(e) => handleSearch(e, index, ind)}
        onChange={(e) => handleSelect(e, index, ind)}
        autoComplete="off"
      />
      <Error id={val?.form?.name?.concat(index + "" + ind)} />
    </React.Fragment>
  );
});

export default Selectcomponent;
