const tables = [
  {
    name: "Email",
  },
  {
    name: "Phone",
  },
  {
    name: "Action",
  },
];

export default tables;
