import React from "react";

// const Environment = "PRODUCTION";
const Environment = "DEVELOPMENT";

const URL =
  Environment === "PRODUCTION"
    ? "https://abrajpropertiesdashboard.masirat.com"
    : Environment === "DEVELOPMENT"
    ? "https://abrajpropertiesdashboard.masirat.com"
    : // "http://localhost:7001"
      "";

export default URL;
