import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Components/Form";
import toast from "react-hot-toast";
import Header from "../../../../Components/Header";
import {
  create_home,
  get_home,
  update_home,
} from "../../../../Controllers/Home/Home";
import forms from "../../../../Data/Forms/Home/Home/forms";
import headers from "../../../../Data/Forms/Home/Home/headers";
import Loadercomponent from "../../../../Components/Loader";
import AddButton from "../../../../Components/Buttons/add";
import RemoveButton from "../../../../Components/Buttons/delete";
import { convert_to_file } from "../../../../Controllers/Global";

const HomeForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const home = id
        ? await update_home({ ...data?.data, id: id })
        : await create_home(data?.data);

      if (home?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(home?.message);
        setButtonLoader(false);
      } else {
        toast?.error(home?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = async (home) => {
    let sliders = home?.sliders ? JSON?.parse(home?.sliders) : null;
    let about_title = home?.about_title ? home?.about_title : "";
    let about_description = home?.about_description
      ? home?.about_description
      : "";
    let project_title = home?.project_title ? home?.project_title : "";
    let projects = home?.projects ? JSON?.parse(home?.projects) : null;
    let image = home?.image ? home?.image : "";
    let file = await convert_to_file(image);
    let features = home?.features ? JSON?.parse(home?.features) : null;
    let development_title = home?.development_title
      ? home?.development_title
      : "";
    let developments = home?.developments
      ? JSON?.parse(home?.developments)
      : null;
    let services_title = home?.services_title ? home?.services_title : "";
    let services = home?.services ? JSON?.parse(home?.services) : null;
    let testimonials_title = home?.testimonials_title
      ? home?.testimonials_title
      : "";
    let testimonials = home?.testimonials
      ? JSON?.parse(home?.testimonials)
      : null;
    let blogs_title = home?.blogs_title ? home?.blogs_title : "";
    let blogs = home?.blogs ? JSON?.parse(home?.blogs) : null;

    let slider_options =
      home?.slider_options?.length > 0 ? home?.slider_options : null;

    let project_options =
      home?.project_options?.length > 0 ? home?.project_options : null;

    let feature_options =
      home?.feature_options?.length > 0 ? home?.feature_options : null;

    let development_options =
      home?.feature_options?.length > 0 ? home?.development_options : null;

    let services_options =
      home?.services_options?.length > 0 ? home?.services_options : null;

    let blogs_options =
      home?.blogs_options?.length > 0 ? home?.blogs_options : null;

    let testimonials_options =
      home?.testimonials_options?.length > 0
        ? home?.testimonials_options
        : null;

    forms[0].options[0].form.value = sliders;
    forms[0].options[0].form.options = slider_options;
    forms[0].options[1].form.value = about_title;
    forms[0].options[2].form.value = about_description;
    forms[0].options[3].form.value = project_title;
    forms[0].options[4].form.value = projects;
    forms[0].options[4].form.options = project_options;
    forms[0].options[5].form.value = [file];
    forms[0].options[6].form.value = features;
    forms[0].options[6].form.options = feature_options;
    forms[0].options[7].form.value = development_title;
    forms[0].options[8].form.value = developments;
    forms[0].options[8].form.options = development_options;
    forms[0].options[9].form.value = services_title;
    forms[0].options[10].form.value = services;
    forms[0].options[10].form.options = services_options;
    forms[0].options[11].form.value = testimonials_title;
    forms[0].options[12].form.value = testimonials;
    forms[0].options[12].form.options = testimonials_options;
    forms[0].options[13].form.value = blogs_title;
    forms[0].options[14].form.value = blogs;
    forms[0].options[14].form.options = blogs_options;

    setLoader(false);
    setValues([...values]);

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const home = await get_home(data);
      if (home?.status) {
        handleData(home?.data);
      } else {
        toast?.error(home?.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default HomeForm;
