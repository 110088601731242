import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_project_plan = async (plan) => {
  try {
    let data = new FormData();
    data.append("image", plan?.image[0]);
    data.append("name", plan?.name);
    data.append("description", plan?.description);
    data.append("type", plan?.type);
    data.append("total", plan?.total);
    data.append("available", plan?.available);
    data.append("price_start", plan?.price_start);
    data.append("price_end", plan?.price_end);
    data.append("floors", JSON?.stringify(plan?.floors));
    data.append("status", plan?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-project-plan`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const update_project_plan = async (plan) => {
  try {
    let data = new FormData();
    data.append("id", plan?.id);
    data.append("image", plan?.image[0]);
    data.append("name", plan?.name);
    data.append("description", plan?.description);
    data.append("type", plan?.type);
    data.append("total", plan?.total);
    data.append("available", plan?.available);
    data.append("price_start", plan?.price_start);
    data.append("price_end", plan?.price_end);
    data.append("floors", JSON?.stringify(plan?.floors));
    data.append("status", plan?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-project-plan`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_project_plan = async (plan) => {
  try {
    const data = {
      id: plan?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-project-plan`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_all_project_plans = async (plan) => {
  try {
    const data = {
      search: plan?.search,
      sort: plan?.sort,
      status: plan?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-project-plans`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

export {
  create_project_plan,
  update_project_plan,
  get_project_plan,
  get_all_project_plans,
};
