import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Components/Form";
import toast from "react-hot-toast";
import Header from "../../../../Components/Header";
import {
  create_about,
  get_about,
  update_about,
} from "../../../../Controllers/About/About";
import forms from "../../../../Data/Forms/About/About/forms";
import headers from "../../../../Data/Forms/About/About/headers";
import Loadercomponent from "../../../../Components/Loader";
import AddButton from "../../../../Components/Buttons/add";
import RemoveButton from "../../../../Components/Buttons/delete";
import { convert_to_file } from "../../../../Controllers/Global";

const AboutForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const about = id
        ? await update_about({ ...data?.data, id: id })
        : await create_about(data?.data);

      if (about?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(about?.message);
        setButtonLoader(false);
      } else {
        toast?.error(about?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = async (about) => {
    let image = about?.image ? about?.image : "";
    let file = await convert_to_file(image);
    let title = about?.title ? about?.title : "";
    let description = about?.description ? about?.description : "";
    let projects = about?.projects ? about?.projects : "";
    let area = about?.area ? about?.area : "";
    let ongoing = about?.ongoing ? about?.ongoing : "";
    let units = about?.units ? about?.units : "";
    let milestones = about?.milestones ? JSON?.parse(about?.milestones) : null;
    let teams = about?.teams ? JSON?.parse(about?.teams) : null;
    let status = about?.status ? about?.status : 0;

    let milestone_options =
      about?.milestone_options?.length > 0 ? about?.milestone_options : null;
    let team_options =
      about?.team_options?.length > 0 ? about?.team_options : null;

    values[0].options[0].form.value = [file];
    values[0].options[1].form.value = title;
    values[0].options[2].form.value = description;
    values[0].options[3].form.value = projects;
    values[0].options[4].form.value = area;
    values[0].options[5].form.value = ongoing;
    values[0].options[6].form.value = units;
    values[0].options[7].form.value = milestones;
    values[0].options[7].form.options = milestone_options;
    values[0].options[8].form.value = teams;
    values[0].options[8].form.options = team_options;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const about = await get_about(data);
      if (about?.status) {
        handleData(about?.data);
      } else {
        toast?.error(about?.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default AboutForm;
