import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_blog = async (blog) => {
  try {
    let data = new FormData();
    data.append("image", blog?.image[0]);
    data.append("title", blog?.title);
    data.append("description", blog?.description);
    data.append("date", blog?.date);
    data.append("author", blog?.author);
    data.append("recomended", JSON?.stringify(blog?.recomended));
    data.append("status", blog?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/create-blog`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const update_blog = async (blog) => {
  try {
    let data = new FormData();
    data.append("id", blog?.id);
    data.append("image", blog?.image[0]);
    data.append("title", blog?.title);
    data.append("description", blog?.description);
    data.append("date", blog?.date);
    data.append("author", blog?.author);
    data.append("recomended", JSON?.stringify(blog?.recomended));
    data.append("status", blog?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/update-blog`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_blog = async (blog) => {
  try {
    const data = {
      id: blog?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-blog`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_all_blogs = async (blog) => {
  try {
    const data = {
      search: blog?.search,
      sort: blog?.sort,
      unit: blog?.unit,
      category: blog?.category,
      brand: blog?.brand,
      type: blog?.type,
      status: blog?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-all-blogs`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

export { create_blog, update_blog, get_blog, get_all_blogs };
