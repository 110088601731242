import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_contact = async (contact) => {
  try {
    let data = {
      area: contact?.area,
      city: contact?.city,
      state: contact?.state,
      country: contact?.country,
      email: contact?.email,
      phone: contact?.phone,
      location: contact?.location,
      teams: JSON?.stringify(contact?.teams),
      brokers: JSON?.stringify(contact?.brokers),
      status: contact?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/create-contact`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const update_contact = async (contact) => {
  try {
    let data = {
      id: contact?.id,
      area: contact?.area,
      city: contact?.city,
      state: contact?.state,
      country: contact?.country,
      email: contact?.email,
      phone: contact?.phone,
      location: contact?.location,
      teams: JSON?.stringify(contact?.teams),
      brokers: JSON?.stringify(contact?.brokers),
      status: contact?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/update-contact`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_contact = async (contact) => {
  try {
    const data = {
      id: contact?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-contact`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_all_contacts = async (contact) => {
  try {
    const data = {
      search: contact?.search,
      sort: contact?.sort,
      unit: contact?.unit,
      category: contact?.category,
      brand: contact?.brand,
      type: contact?.type,
      status: contact?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-contacts`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

export { create_contact, update_contact, get_contact, get_all_contacts };
