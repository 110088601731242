import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Components/Form";
import toast from "react-hot-toast";
import Header from "../../../../Components/Header";
import {
  create_broker,
  get_broker,
  update_broker,
} from "../../../../Controllers/Careers/Brokers";
import forms from "../../../../Data/Forms/Careers/Brokers/forms";
import headers from "../../../../Data/Forms/Careers/Brokers/headers";
import Loadercomponent from "../../../../Components/Loader";
import AddButton from "../../../../Components/Buttons/add";
import RemoveButton from "../../../../Components/Buttons/delete";

const CareerBrokersForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const item = id
        ? await update_broker({ ...data?.data, id: id, image: "" })
        : await create_broker(data?.data);

      if (item?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(item?.message);
        setButtonLoader(false);
      } else {
        toast?.error(item?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = (item) => {
    let image = item?.image ? item?.image : "";
    let name = item?.name ? item?.name : "";
    let unit = item?.unit?._id ? item?.unit?._id : null;
    let category = item?.category?._id ? item?.category?._id : null;
    let brand = item?.brand?._id ? item?.brand?._id : null;
    let purchase_price = item?.purchase_price ? item?.purchase_price : 0;
    let sale_price = item?.sale_price ? item?.sale_price : 0;
    let tax = item?.tax ? item?.tax : 0;
    let type = item?.type ? item?.type : 0;
    let stock = item?.stock ? item?.stock : "";
    let notify = item?.notify ? item?.notify : "";
    let status = item?.status ? item?.status : 0;

    let unit_option = item?.unit?._id
      ? [{ value: item?.unit?._id, label: item?.unit?.name }]
      : null;
    let category_option = item?.category?._id
      ? [{ value: item?.category?._id, label: item?.category?.name }]
      : null;
    let brand_option = item?.brand?._id
      ? [{ value: item?.brand?._id, label: item?.brand?.name }]
      : null;

    values[0].options[0].form.value = image;
    values[0].options[1].form.value = name;

    values[1].options[0].form.value = unit;
    values[1].options[0].form.options = unit_option;
    values[1].options[1].form.value = category;
    values[1].options[1].form.options = category_option;
    values[1].options[2].form.value = brand;
    values[1].options[2].form.options = brand_option;

    values[2].options[0].form.value = purchase_price;
    values[2].options[1].form.value = sale_price;
    values[2].options[2].form.value = tax;

    values[3].options[0].form.value = type;
    values[3].options[1].form.value = stock;
    values[3].options[2].form.value = notify;

    values[4].options[0].form.value = status;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const item = await get_broker(data);
      if (item?.status) {
        handleData(item?.data);
      } else {
        toast?.error(item?.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default CareerBrokersForm;
