import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_project = async (project) => {
  try {
    let data = new FormData();
    data.append("image", project?.image[0]);
    data.append("name", project?.name);
    data.append("description", project?.description);
    data.append("property_size", project?.property_size);
    data.append("property_price", project?.property_price);
    data.append("property_type", project?.property_type);
    data.append("project_duration", project?.project_duration);
    data.append("project_status", project?.project_status);
    data.append("floors", project?.floors);
    data.append("plan_size", project?.plan_size);
    data.append("progress", project?.progress);
    data.append("area", project?.area);
    data.append("city", project?.city);
    data.append("state", project?.state);
    data.append("country", project?.country);
    data.append("google_maps", project?.google_maps);
    data.append("latitude_longitude", project?.latitude_longitude);
    data.append("sliders", JSON?.stringify(project?.sliders));
    data.append("gallaries", JSON?.stringify(project?.gallaries));
    data.append("amenities", JSON?.stringify(project?.amenities));
    data.append("nearbies", JSON?.stringify(project?.nearbies));
    data.append("types", JSON?.stringify(project?.types));
    data.append("plans", JSON?.stringify(project?.plans));
    data.append("status", project?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/create-project`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const update_project = async (project) => {
  try {
    let data = new FormData();
    data.append("id", project?.id);
    data.append("image", project?.image[0]);
    data.append("name", project?.name);
    data.append("description", project?.description);
    data.append("property_size", project?.property_size);
    data.append("property_price", project?.property_price);
    data.append("property_type", project?.property_type);
    data.append("project_duration", project?.project_duration);
    data.append("project_status", project?.project_status);
    data.append("floors", project?.floors);
    data.append("plan_size", project?.plan_size);
    data.append("progress", project?.progress);
    data.append("area", project?.area);
    data.append("city", project?.city);
    data.append("state", project?.state);
    data.append("country", project?.country);
    data.append("google_maps", project?.google_maps);
    data.append("latitude_longitude", project?.latitude_longitude);
    data.append("sliders", JSON?.stringify(project?.sliders));
    data.append("gallaries", JSON?.stringify(project?.gallaries));
    data.append("amenities", JSON?.stringify(project?.amenities));
    data.append("nearbies", JSON?.stringify(project?.nearbies));
    data.append("types", JSON?.stringify(project?.types));
    data.append("plans", JSON?.stringify(project?.plans));
    data.append("status", project?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/update-project`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_project = async (project) => {
  try {
    const data = {
      id: project?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-project`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_all_projects = async (project) => {
  try {
    const data = {
      search: project?.search,
      sort: project?.sort,
      status: project?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-projects`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

export { create_project, update_project, get_project, get_all_projects };
