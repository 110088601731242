import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_broker = async (broker) => {
  try {
    let data = new FormData();
    data.append("image", broker?.image[0]);
    data.append("name", broker?.name);
    data.append("designation", broker?.designation);
    data.append("phone", broker?.phone);
    data.append("email", broker?.email);
    data.append("description", broker?.description);
    data.append("status", broker?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/create-broker`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const update_broker = async (broker) => {
  try {
    let data = new FormData();
    data.append("id", broker?.id);
    data.append("image", broker?.image[0]);
    data.append("name", broker?.name);
    data.append("designation", broker?.designation);
    data.append("phone", broker?.phone);
    data.append("email", broker?.email);
    data.append("description", broker?.description);
    data.append("status", broker?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/update-broker`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_broker = async (broker) => {
  try {
    const data = {
      id: broker?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-broker`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_all_brokers = async (broker) => {
  try {
    const data = {
      search: broker?.search,
      sort: broker?.sort,
      unit: broker?.unit,
      category: broker?.category,
      brand: broker?.brand,
      type: broker?.type,
      status: broker?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-brokers `,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

export { create_broker, update_broker, get_broker, get_all_brokers };
