const headers = {
  name: "Project plans",
  navigate: {
    path: [
      {
        name: "Plans",
        path: "/app/projects/plans",
      },
      {
        name: "Add",
        path: "",
      },
    ],
  },
};

export default headers;
