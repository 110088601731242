import { LoadingOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const SavebuttonComponent = (props) => {
  const { form, handleBack } = props;

  return (
    <React.Fragment>
      {form?.button ? (
        <Button
          className="text-medium form-save-button"
          type="primary"
          htmlType="submit"
        >
          {form?.button}
        </Button>
      ) : (
        <React.Fragment>
          <Button
            className="mr-2 text-medium form-back-button"
            htmlType="button"
            onClick={handleBack}
          >
            Back
          </Button>
          <div className="float-end">
            <Button
              className="mr-2 text-medium form-cancel-button"
              htmlType="reset"
            >
              Cancel
            </Button>
            <Button
              className="text-medium form-save-button"
              type="primary"
              htmlType="submit"
              disabled={props?.buttonLoader}
            >
              {props?.buttonLoader ? (
                <Spin
                  indicator={<LoadingOutlined spin />}
                  size="small"
                  className="w-10"
                />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default SavebuttonComponent;
