import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import forms from "../../../../Data/Forms/Projects/Nearby/forms";
import headers from "../../../../Data/Forms/Projects/Nearby/headers";
import {
  create_project_nearby,
  get_project_nearby,
  update_project_nearby,
} from "../../../../Controllers/Projects/Nearby";
import Header from "../../../../Components/Header";
import Form from "../../../../Components/Form";
import Loadercomponent from "../../../../Components/Loader";

const ProjectNearbyForm = () => {
  let { id } = useParams();

  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const nearby = id
        ? await update_project_nearby({ id: id, ...data?.data })
        : await create_project_nearby(data?.data);

      if (nearby?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(nearby?.message);
        setButtonLoader(false);
      } else {
        toast?.error(nearby?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = (nearby) => {
    let name = nearby?.name ? nearby?.name : "";
    let status = nearby?.status ? nearby?.status : 0;

    forms[0].options[0].form.value = name;
    forms[0].options[1].form.value = status;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      headers.navigate.path[1].name = "Edit";

      const data = {
        id: id,
      };

      const nearby = await get_project_nearby(data);

      if (nearby?.status) {
        handleData(nearby?.data);
      } else {
        toast?.error(nearby?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [buttonLoader]);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Form
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default ProjectNearbyForm;
