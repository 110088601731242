import React from "react";
import Required from "../Required";

const Headercomponent = (props) => {
  const { val, index, ind } = props;

  return (
    <React.Fragment>
      <div className="pb-2 text-medium text-gray-500 text-sm">
        {val?.name && (
          <label htmlFor={index?.toString()?.concat(ind)}>
            {val?.name} {val?.form?.required && <Required />}
          </label>
        )}
      </div>
    </React.Fragment>
  );
};

export default Headercomponent;
