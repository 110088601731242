const forms = [
  {
    name: "Candidates",
    options: [
      {
        name: "Date",
        type: "date",
        form: {
          type: "text",
          name: "date",
          placeholder: "Date",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Name",
        type: "input",
        form: {
          type: "text",
          name: "name",
          placeholder: "name",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Email",
        type: "input",
        form: {
          type: "text",
          name: "email",
          placeholder: "Email",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Phone",
        type: "input",
        form: {
          type: "text",
          name: "phone",
          placeholder: "Phone",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Role",
        type: "input",
        form: {
          type: "text",
          name: "role",
          placeholder: "Role name",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Experience",
        type: "input",
        form: {
          type: "text",
          name: "role",
          placeholder: "Experience",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Country",
        type: "input",
        form: {
          type: "text",
          name: "role",
          placeholder: "Country",
          class: "",
          value: "",
          required: true,
        },
      },
      //   {
      //     name: "Status",
      //     type: "select",
      //     form: {
      //       name: "status",
      //       allowClear: false,
      //       placeholder: "Status",
      //       value: 1,
      //       options: [
      //         {
      //           value: 1,
      //           label: "Active",
      //         },
      //         {
      //           value: 0,
      //           label: "In-active",
      //         },
      //       ],
      //       required: false,
      //     },
      //   },
    ],
  },
];

export default forms;
