const headers = {
  name: "Sliders",
  navigate: {
    path: [
      {
        name: "Sliders",
        path: "/app/home/sliders",
      },
      {
        name: "Add",
        path: "",
      },
    ],
  },
};

export default headers;
