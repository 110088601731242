const forms = [
  {
    name: "Project",
    options: [
      {
        name: "Image",
        type: "upload",
        form: {
          type: "text",
          name: "image",
          placeholder: "Recomended (633 X 520)",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Name",
        type: "input",
        form: {
          type: "text",
          name: "name",
          placeholder: "Project name",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Description",
        type: "input",
        form: {
          type: "text",
          name: "description",
          placeholder: "Project description",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Property size",
        type: "input",
        form: {
          type: "text",
          name: "property_size",
          placeholder: "Property size",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Property Price",
        type: "input",
        form: {
          type: "text",
          name: "property_price",
          placeholder: "Property price",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Property Type",
        type: "input",
        form: {
          type: "text",
          name: "property_type",
          placeholder: "Propert type",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Project duration",
        type: "input",
        form: {
          type: "text",
          name: "project_duration",
          placeholder: "Project duration",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Project status",
        type: "input",
        form: {
          type: "text",
          name: "project_status",
          placeholder: "Status",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Floors",
        type: "input",
        form: {
          type: "text",
          name: "floors",
          placeholder: "Project Total Floors",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Plan size",
        type: "input",
        form: {
          type: "text",
          name: "plan_size",
          placeholder: "Plan size (Min-Max)",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Progress",
        type: "input",
        form: {
          type: "text",
          name: "progress",
          placeholder: "Progress %",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Area",
        type: "input",
        form: {
          type: "text",
          name: "area",
          placeholder: "Project area",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "City",
        type: "input",
        form: {
          type: "text",
          name: "city",
          placeholder: "Project city",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "State",
        type: "input",
        form: {
          type: "text",
          name: "state",
          placeholder: "Project state",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Country",
        type: "input",
        form: {
          type: "text",
          name: "country",
          placeholder: "Project country",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Google maps",
        type: "input",
        form: {
          type: "text",
          name: "google_maps",
          placeholder: "Project google map location",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Latitude & Longitude",
        type: "input",
        form: {
          type: "text",
          name: "latitude_longitude",
          placeholder: "Project latitude & longitude",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Sliders",
        type: "selectandadd",
        path: "/app/projects/sliders/add",
        form: {
          mode: "multiple",
          name: "sliders",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Project sliders",
          api: "get-all-project-sliders",
          options: null,
          value: null,
          required: true,
        },
      },
      {
        name: "Gallaries",
        type: "selectandadd",
        path: "/app/projects/gallery/add",
        form: {
          mode: "multiple",
          name: "gallaries",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Project gallaries",
          api: "get-all-project-gallaries",
          options: null,
          value: null,
          required: true,
        },
      },
      {
        name: "Amenities",
        type: "selectandadd",
        path: "/app/projects/amenities/add",
        form: {
          mode: "multiple",
          name: "amenities",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Project amenities",
          api: "get-all-project-amenities",
          options: null,
          value: null,
          required: true,
        },
      },
      {
        name: "Nearbies",
        type: "selectandadd",
        path: "/app/projects/nearbies/add",
        form: {
          mode: "multiple",
          name: "nearbies",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Project nearbies",
          api: "get-all-project-nearbies",
          options: null,
          value: null,
          required: true,
        },
      },
      {
        name: "Types",
        type: "selectandadd",
        path: "/app/projects/types/add",
        form: {
          mode: "multiple",
          name: "types",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Project types",
          api: "get-all-project-types",
          options: null,
          value: null,
          required: true,
        },
      },
      {
        name: "Plans",
        type: "selectandadd",
        path: "/app/projects/plans/add",
        form: {
          mode: "multiple",
          name: "plans",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Project plans",
          api: "get-all-project-plans",
          options: null,
          value: null,
          required: true,
        },
      },
      {
        name: "Status",
        type: "select",
        form: {
          name: "status",
          allowClear: false,
          placeholder: "Status",
          value: 1,
          options: [
            {
              value: 1,
              label: "Active",
            },
            {
              value: 0,
              label: "In-active",
            },
          ],
          required: false,
        },
      },
    ],
  },
];

export default forms;
